export const Footer = (props) => {
  
  return (
    <div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2024 . Design by{' '}
            <a href='https://www.linkedin.com/company/cybertech-consultoria/' rel='nofollow'>
              CyberTech Tecnologia
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
